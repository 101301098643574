import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto pt-4 md:px-10 lg:px-15 px-4 mt-8 mb-32">
      <h2 className="text-3xl font-bold mb-2 font-mono">About Us</h2>
      <p className="text-gray-700 mb-4 font-mono">
        At Sub2Buzz, we leverage the power of the Google Trends API to bring you the most current and relevant news stories from around the world. Our cutting-edge technology continuously monitors trending topics, ensuring that you stay informed about the latest developments in real-time. Whether it's breaking news, industry updates, or niche interests, we have our finger on the pulse of what matters to you.
      </p>
      <p className="text-gray-700 mb-4 font-mono">
        By signing up with Sub2Buzz, you can personalize your news experience like never before. Choose the topics that interest you the most, and receive friendly, concise email updates directly to your inbox. Our goal is to keep you informed without overwhelming you, providing just the right amount of information to keep you in the loop. Join Sub2Buzz today and stay ahead of the curve with tailored news updates that match your interests.
      </p>
    </div>
  );
};

export default AboutUs;