import { FaInstagram } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";

function Footer() {
    return (
        <div className='fixed bottom-0 w-full bg-yellow-50 py-4'>
            <main className='overflow-hidden pb-10'>
                <div className='flex justify-center space-x-4'>
                <a href='https://www.facebook.com/'>
                    <FaSquareXTwitter className='text-gray-600' size={30} />
                </a>
                <a href='https://www.facebook.com/'>
                    <FaInstagram className='text-pink-500' size={30} />
                </a>
                </div>
            </main>
        </div>
    )
}

export default Footer;