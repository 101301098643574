import './App.css';
import './index.css'; // Import Tailwind CSS styles
import RootLayout from './pages/root';
import Home from './pages/Home';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

// app router
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    // errorElement: <Error />,
    children: [
      { path: '/', element: <Home /> },
    ]
  }
])

function App() {
  return (
    <div className='bg-yellow-50'>
      <main className='h-screen overflow-auto bg-yellow-50'>
        <RouterProvider router={router} />
      </main>
    </div>
  )
}

export default App;
