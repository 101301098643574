import { useEffect, useState } from 'react';
import './TypingAnimation.css'

function TrendingTopics() {
    const topics = [
        {name: "Elon Musk", value: "elon"},
        {name: "LeBron James", value: "bron"},
        {name: "S&P 500", value: "sp500"},
        {name: "Sam Altman", value: "neuralink"},
        {name: "Tesla", value: "tesla"},
        {name: "Bitcoin", value: "btc"},
        {name: "World Cup", value: "worldcup"},
        {name: "Messi", value: "messi"},
        {name: "LIV Golf", value: "liv"},
        {name: "Tim Apple", value: "timmy"},
        {name: "Bezos", value: "bezos"},
        {name: "Taylor Swift", value: "taylor"},
        {name: "NASDAQ", value: "nasdaq"},
    ]
    const [rotateTopic, setRotateTopic] = useState(topics[0]);
    const [intervalId, setIntervalId] = useState(null);
    var i = 0;
    useEffect(() => {
        const id = setInterval(() => {
          setRotateTopic(topics[i]);
          i = i + 1;
          if (i > (topics.length-1)) {
            i = 0;
          }
        }, 5000);
        setIntervalId(id);
        // Cleanup function
        return () => {
          clearInterval(intervalId);
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    return (
        <>
        <div className='bg-purple-200 border-2 border-purple-800 border-solid rounded-3xl px-6 py-2 min-w-[12vw] flex items-center typewriter z-40'>
            <p className='text-purple-800 mx-auto typing-erase'>{rotateTopic.name}</p>
        </div>
        </>
    )
}

export default TrendingTopics;