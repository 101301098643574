import { useState } from 'react';
import subscribeHandler from './lamdaTest'
import { IoIosNotifications } from "react-icons/io";
import bounce from './NotificationBounce.css'
import { square } from 'ldrs'

function SignUp() {
    const [email, setEmail] = useState("");
    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    }
    const [topic, setTopic] = useState("");
    const topicChangeHandler = (event) => {
        setTopic(event.target.value);
    }

    const [formData, setFormData] = useState({});
    const [signUpSuccess, setSignUpSuccess] = useState('');

    const notifyList = [
        {name: "Every 30 Days", slider: 0, days: 30, iconSize: 30},
        {name: "Every 14 Days", slider: 1, days: 14, iconSize: 30},
        {name: "Every 7 Days", slider: 2, days: 7, iconSize: 30},
        {name: "Every Day", slider: 3, days: 1, iconSize: 30},
    ]
    const [daysNotified, setDaysNotified] = useState(notifyList[0])
    const daysNotifiedChangeHandler = (event) => {
        setDaysNotified(notifyList[parseInt(event.target.value)])
    }

    square.register()
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        // prevent the URL data fill
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        // run the API call for submission
        setSignUpSuccess('');
        setIsLoading(true);
        subscribeHandler(email, topic)
            .then(data => {
                setSignUpSuccess(data);
                setIsLoading(false);
            })
            .catch(error => {
                setSignUpSuccess(error);
                setIsLoading(false);
            });
    };

    return (
        <div className="container mx-auto p-4 bg-yellow-50">
            <div>
                <h1 className="text-3xl font-bold text-center mb-4">Join The Buzz</h1>
            </div>
          <form className="max-w-md mx-auto">
            <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 font-mono">Email</label>
                <input 
                    type="email" 
                    id="email" 
                    name="email"
                    className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500" 
                    onChange={emailChangeHandler}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="topics" className="block text-gray-700 font-mono">Topics of Interest</label>
                <input 
                    type="text" 
                    id="topics" 
                    name="topics" 
                    className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500" 
                    onChange={topicChangeHandler}
                />
            </div>
            <div className="mb-4 grid grid-rows-1 items-center justify-center">
                <div className="flex items-center">
                    <IoIosNotifications 
                        size={daysNotified.iconSize}
                        className={bounce ? 'bounce' : ''}
                    />
                    <label htmlFor="days" className="text-right mr-4 font-mono">{daysNotified.name}</label>
                </div>
            </div>
            <div className="mb-4 grid grid-rows-1 items-center justify-center">
                <div className='w-64'>
                    <input
                        type="range"
                        id="days"
                        name="days"
                        min="0"
                        max="3"
                        value={daysNotified.slider}
                        className="w-full h-1 bg-gray-800 rounded-full outline-none appearance-none"
                        onChange={(event) => daysNotifiedChangeHandler(event)}
                    />
                </div>
            </div>
            <div className='w-full flex flex-col items-center justify-center'>
                {isLoading ?
                    <>
                        <button 
                            type="submit" 
                            className="w-full p-4 font-mono text-xl bg-gray-800 text-white rounded-md"
                            onClick={handleSubmit}
                            disabled={true}
                            >
                                <l-square size="20" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="1.2"  color="white"></l-square>
                        </button>
                    </>
                    :
                    <>
                        {(signUpSuccess === '') ?
                            <>
                            {(email.length > 0 && topic.length > 0) ?
                                <button 
                                    type="submit" 
                                    className="w-full p-4 font-mono text-xl bg-gray-800 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                    onClick={handleSubmit}
                                    disabled={false}
                                    >
                                        Sign Up!
                                </button>
                            :
                                <button 
                                    type="submit" 
                                    className="w-full p-4 font-mono text-xl bg-gray-200 text-white rounded-md"
                                    onClick={handleSubmit}
                                    disabled={true}
                                    >
                                        Sign Up!
                                </button>
                            }
                            </>
                            : 
                                (signUpSuccess === 200) ?
                                    <button 
                                        type="submit" 
                                        className="w-full p-4 font-mono text-xl bg-gray-800 text-white rounded-md"
                                        onClick={handleSubmit}
                                        disabled={true}
                                        >
                                            Sign up successful!
                                    </button>
                                : 
                                    <button 
                                        type="submit" 
                                        className="w-full p-4 font-mono text-xl bg-gray-800 text-white rounded-md"
                                        onClick={handleSubmit}
                                        disabled={true}
                                        >
                                            Sign up failed!
                                    </button>
                        }
                    </>
                }
                <div className='flex pt-4 items-center justify-center z-40'>
                    <a 
                        href="https://www.buymeacoffee.com/laminar"
                        className='w-fit'
                    >
                        <img 
                            className='object-center'
                            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=laminar&button_colour=BD5FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00" 
                        />
                    </a>
                </div>
            </div>
          </form>
        </div>
    );
}

export default SignUp;