
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import
// const { LambdaClient, GetFunctionCommand } = require("@aws-sdk/client-lambda"); // CommonJS import

async function subscribeHandler(email, topic) {
    const client = new LambdaClient({
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
        },
        region: process.env.REACT_APP_AWS_REGION
    });
    const command = new InvokeCommand({
        FunctionName: "subscribe-notify-me",
        Payload: JSON.stringify({
            "email": email,
            "topic": topic
        }),
    });
    const res = await client.send(command);
    return res.StatusCode;
}

// async function unsubscribeHandler(email, topic) {
//     const client = new LambdaClient({
//         credentials: {
//             accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//             secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
//         },
//         region: process.env.REACT_APP_AWS_REGION
//     });
//     const command = new InvokeCommand({
//         FunctionName: "unsubscribe-notify-me",
//         Payload: JSON.stringify({
//             "email": email,
//             "topic": topic
//         }),
//       });

//       const { Payload, LogResult } = await client.send(command);
// }

export default subscribeHandler;