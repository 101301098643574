import Navbar from '../Navbar';
import SignUp from './signUp';
import AboutUs from './AboutUs';
import Footer from './Footer'
import TrendingTopics from './trendingTopics';

function Home() {
  return (
      <div className="h-fit bg-yellow-50">
        <Navbar className="fixed top-0 w-full z-50" />
        <div className='flex justify-center py-10 w-full pt-12'>
          <TrendingTopics />
        </div>
        <div className='flex flex-col lg:flex-row lg:px-20'>
          <SignUp />
          <AboutUs />
        </div>
        <Footer />
      </div>
  );
}

export default Home;


